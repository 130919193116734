@import "../../assets/styles/_variables.scss";
@import "../../assets/styles/_mixins.scss";

.identity {
   background-color: $primary;
   .container {
      .columns {
         .column {
            .call-us {
               .whatsApp {
                  position: relative;
                  color: $primary;
                  span {
                     display: block;
                     position: absolute;
                     padding: 0;
                     margin: 0;
                     color: inherit;
                  }
                  .title {
                     font-size: 0.8em;
                     font-weight: 400;
                     top: -24px;
                     right: 37px;
                  }
                  .subtitle {
                     font-size: 1em;
                     font-weight: 700;
                     bottom: 12px;
                     right: 26px;
                  }
                  &:hover {
                     color: black;
                  }
               }
            }
         }
      }
   }
}
