@import "../../assets/styles/_variables.scss";

.awareness {
    padding: 3rem 0;
    background-color: white;
    .awareness-box {
        .awareness-title {
            font-size: 1.8rem;
            color: #3E3E3E;
            span {
                color: $primary;
                font-size: 2rem;
                font-weight: bold;
            }
        }
        .notice-box {
            display: flex;
            background-color: $primary;
            color: white;
            padding: 1em;
            border-radius: 1em;
            align-items: center;
            box-shadow: 2px #000000;
            margin: 2rem 0;
            p {
                span {
                    font-weight: bold;
                }
            }
            .shield {
                padding: 1.2em;
            }
        }
        .question {
            color: #3E3E3E;
            p {
                span {
                    color: $primary;
                    font-weight: bold;
                }
            }
        }
    }
}