@import "../../assets/styles/_variables.scss";

.corporate {
   background-color: white;
   padding-bottom: 3rem;
   .title {
      font-weight: lighter;
      span {
         font-weight: bold;
         color: $primary;
      }
   }
}
