.hero.contact-us {
   background-image: url("../../assets/images/services-herox2.png");
   background-size: cover;
   background-position: center center;
   min-height: 300px;
   .hero-body {
      position: relative;
      padding: 0;
      color: white;
      .hero-title {
         position: absolute;
         top: 100px;
         left: calc(50% - 470px);
         font-size: 3em;
         line-height: 1.1em;
         font-weight: 600;
         border-bottom: 8px solid white;
      }
   }
}

.contact-us {
   .title {
      font-size: 2rem;
      font-weight: bold;
      color: #75212c;
   }
   background: #fff;
   .column {
      padding: 3rem 0;
   }
   form {
      fieldset {
         padding: 2rem 1rem;
         border: 2px #c0c0c0 solid;
         border-radius: 10px;
         input {
            border: 1px #c0c0c0 solid;
         }
         .submit-btn {
            color: #75212c;
            &:hover {
               color: white;
               background-color: #75212c;
               border: 2px solid #75212c;
            }
         }
      }
   }
   .is-formtext {
      padding: 3rem 0.8rem;
      p {
         line-height: 1.6rem;
         padding: 1rem 0px;
      }
   }
   .is-formbox {
      padding: 3rem 0.8rem;
   }
}
