$family-serif: "Open Sans";

// Set your brand colors
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$greydark: #3E3E3E;
$red: #852533;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

// Update Bulma's global variables
$family-sans-serif: "Open Sans", sans-serif;
$grey-dark: $greydark;
$grey-light: $beige-light;
$primary: $red;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Navbar modifications
$navbar-item-img-max-height: 4.5rem;