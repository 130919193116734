@import "../../assets/styles/_variables.scss";

.footer {
   background-color: $primary;
   padding: 0px;
   .getBudgetNow {
      padding: 3rem;
      .title {
         font-weight: lighter;
         color: white;
      }
      .button {
         border: 2px solid white;
         &:hover {
            border: 2px solid red;
         }
      }
   }
   .footerContent {
      background: black;
      padding: 3rem;
      .footerLinks {
         color: white;
         a {
            &:not(:last-child) {
               padding: 1rem;
            }
            color: white;
            padding-left: 1rem;
            &:hover {
               color: $primary;
            }
         }
         a:not(:last-child)::after {
            content: "";
            border: 1px solid #fff;
            border-width: 1px 1px 0 0;
            height: 100%;
            position: relative;
            top: 0;
            right: -12px;
         }
      }
      .copyright {
         color: white;
      }
   }
}
