.aboutUs {
   background-image: url('/assets/images/politalking.png');
   background-position: center;
   background-size: cover;
   color: white;
   padding: 3rem 0;
   .context-item {
      padding-bottom: 2rem;
      img {
         padding-right: 18px;
         float: left;
      }
   }
}