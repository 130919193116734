.hero.services {
   background-image: url('../../assets/images/services-herox2.png');
   background-size: cover;
   background-position: center center;
   min-height: 300px;
   .hero-body {
      position: relative;
      padding: 0;
      color: white;
      .hero-title {
         position: absolute;
         top: 100px;
         left: calc(50% - 470px);
         font-size: 3em;
         line-height: 1.1em;
         text-transform: uppercase;
         font-weight: 600;
         border-bottom: 8px solid white;
      }
   }
}

.content {
   background-color: #F8F8F8;
   margin-bottom: 0px !important;
   .picholder {
      position: relative;
      background-color: gray;
      min-width: 400px;
      width: 452px;
      min-height: 543px;
      height: 543px;
      border-radius: 12px;
      .itemLabel {
         position: absolute;
         width: 457px;
         height: 87px;
         bottom: 100px;
         right: -15px;
         padding: 30px 6px 10px 16px;
         background: #000;
         border-radius: 12px;
         &.smaller {
            padding: 12px 6px 10px 16px;
         }
         .theTitle {
            font-size: 1.1rem;
            font-weight: bold;
            color: white;
            margin: 0;
         }
         .theSubtitle {
            font-size: 1.2rem;
            font-weight: bold;
            color: white;
            padding: 0;
            margin: 0;
         }
      }
   }
}

.content.left-cta {
   background: #fff;
   .picholder {
      padding-left: 15px;
      .itemLabel {
         right: 13px;
      }
   }
}

#gsrc {
   background-image: url('../../assets/images/services/GSRC.jpeg');
   background-size: cover;
   background-position: center center;
}

#gscc {
   background-image: url('../../assets/images/services/GSCC.jpeg');
   background-size: cover;
   background-position: center center;
}

#gsco {
   background-image: url('../../assets/images/services/GSCO.jpeg');
   background-size: cover;
   background-position: center center;
}

#gsei {
   background-image: url('../../assets/images/services/GSEI.jpeg');
   background-size: cover;
   background-position: center center;
}

#gse {
   background-image: url('../../assets/images/services/GSE.jpeg');
   background-size: cover;
   background-position: center center;
}

#gsc {
   background-image: url('../../assets/images/services/GSC.jpeg');
   background-size: cover;
   background-position: center center;
}