@import "../../assets/styles/_variables.scss";

.process {
   background: white;
   .title {
      color: #3E3E3E;
      font-weight: lighter;
      span {
         color: $primary;
         font-weight: bold;
      }
   }
   .steps {
      .processIcon {
         &.the-phone {
            padding: 16px;
         }
      }
      .title {
         color: $primary;
      }
   }
   .getBudgetBox {
      .getBudgetBtn {
         font-size: 1rem;
      }
   }
}