.hero.root {
    background-image: url("/assets/images/security-hero-main.png");
    background-size: cover;
    background-position: center;
    min-height: 414px;
    color: white;
    .columns {
        height: 414px;
    }
    .hero-body {
        padding: 0;
        .hero-title {
            font-size: 2em;
            font-weight: bold;
            line-height: 1.4em;
            text-align: right;
        }
        .hero-text {
            font-size: 1.1em;
            font-weight: normal;
            text-align: right;
        }
    }
}
