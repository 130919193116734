@import "../../assets/styles/_variables.scss";

.know-more {
    background-image: url('/assets/images/security-red-box.png');
    background-position: center;
    background-size: cover;
    .container {
        .columns {
            .column {
               color: white;
                h1.title {
                    color: white;
                    font-weight: 400;
                    font-size: 1.8rem;
                }
            }
        }
    }
}