@import "../../assets/styles/variables";

.hero.about-us {
   background-image: url("/assets/images/about-us-hero.png");
   background-size: cover;
   background-position: center;
   color: white;
   min-height: 300px;
   .hero-body {
      position: relative;
      padding: 0;
      .hero-title {
         position: absolute;
         top: 100px;
         left: calc(50% - 470px);
         font-size: 3em;
         line-height: 1.1em;
         text-transform: uppercase;
         font-weight: 600;
         border-bottom: 8px solid white;
      }
   }
}

.content {
   color: #707070;
   padding-top: 5em;
   padding-bottom: 5em;
   .title {
      font-weight: bold;
      color: $primary;
   }
   .text {
      font-size: 1.3em;
   }
}

.check-services {
   background: #444;
   color: white;
   .container {
      text-align: center;
      min-height: 545px;
      padding: 205px 160px;
   }
   .title {
      color: white;
      font-weight: 400;
   }
   .text {
      padding-bottom: 2rem;
   }
   .button.is-transparent {
      background: none;
      color: white;
      padding: 1.6rem 6rem;
   }
}

#servicesMosaic {
   background-image: url("/assets/images/services/mosaico.jpeg");
   background-repeat: repeat;
   background-size: cover;
   background-position: center;
}